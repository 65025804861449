
import React, { useState, useEffect, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import PrimaryLayout from "components/templates/layout/PrimaryLayout";
import Spinner from "components/atoms/Spinner";
import useAuth from "hooks/useAuth";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import moment from 'moment';
import { ApiCall } from "utils/ApiCall";

const MySchools     = lazy(() => import('pages/myschools'));
const CreateSchool  = lazy(() => import('pages/create-school'));
const Settings      = lazy(() => import('pages/settings'));
const Logout        = lazy(() => import('pages/logout'));

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <PrimaryLayout>
                <MySchools />
            </PrimaryLayout>
        ),
    },
    {
        path: "create-school",
        element: (
            <PrimaryLayout>
                <CreateSchool />
            </PrimaryLayout>
        )
    },
    {
        path: "settings",
        element: (
            <PrimaryLayout>
                <Settings />
            </PrimaryLayout>
        ),
    },
    {
        path: "logout",
        element: (
            <Logout />
        ),
    },
]);

const App = () => {
    const { initializing, user } = useAuth();
    const [userHash, setUserHash] = useState('');

    useEffect(() => {
        ApiCall.get('/sso/users/generate-hash')
        .then((res) => {
            setUserHash(res.data.user_hash);
        })
        .catch((err) => {
            console.log(`Error: ${err?.response?.data?.message}`);
        })
    }, []);

    if (initializing) return null;

    if (!user) {
        window.location.href = `https://${process.env.REACT_APP_COGNITO_MAIN_OAUTH_DOMAIN}/oauth2/authorize?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+profile+aws.cognito.signin.user.admin&redirect_uri=${origin}`
        return;
    }

    return (
        <Suspense fallback={<Spinner />}>
            <RouterProvider router={router} />
            {!!userHash && process.env.REACT_APP_API_BASE === 'https://api.eduqat.com' && (
                <Helmet>
                    <script>
                        {`
                            window.intercomSettings = {
                                api_base: "https://api-iam.intercom.io",
                                app_id: "v66a8a4w",
                                name: "${user?.attributes?.name}", // Full name
                                user_id: "${user?.attributes?.sub}", // a UUID for your user
                                email: "${user?.attributes?.email}", // the email for your user
                                user_hash: "${userHash}",
                                created_at: "${moment().utc().unix()}" // Signup date as a Unix timestamp
                            }
                        `}
                    </script>
                    <script>
                        {`
                            // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/v66a8a4w'
                            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/v66a8a4w';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
                        `}
                    </script>
                </Helmet>
            )}
        </Suspense>
    )
}

export default App