import { createContext, useState, useEffect, useCallback } from "react";
import { Auth, Hub } from "aws-amplify";

const useAuthContextValue = () => {
  const [auth, setAuth] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const [redirect, setRedirect] = useState(null);
  const [user, setUser] = useState(null);

  const signOut = useCallback(() => {
    if (!auth) return;
    auth.signOut({ global: true });
  }, [auth])

  const updateUserAttributes = useCallback((attributes) => {
    if (!auth) return;
    return auth.updateUserAttributes(auth.user, attributes).then(res => {
      return auth.currentAuthenticatedUser().then(user => {
        setUser(user);
        return true;
      })
    })
  }, [auth])

  const verifyEmailValidationCode = useCallback((email, code) => {
    if (!auth) return;
    return auth.verifyCurrentUserAttributeSubmit('email', code).then(() => {
      return auth.currentAuthenticatedUser().then(user => {
        setUser(user);
        return true;
      })
    });
  }, [auth])

  const changePassword = useCallback((oldPassword, newPassword) => {
    if (!auth) return;
    return auth.changePassword(auth.user, oldPassword, newPassword)
  }, [auth])

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload, ...rest }) => {
      const { event, data } = payload;
      switch (event) {
        case 'parsingCallbackUrl':
          const state = new URLSearchParams(data.url).get('state');
          switch (state) {
            case 'create-school':
              setRedirect(`/create-school`)
              break;
            default:
              break;
          }
          break;
        case 'signIn':
          console.log('data signIn', { redirect, data });
          setUser(data);
          if (redirect) {
            window.location.href = window.location.origin + redirect
          }
          break;
        case 'signOut':
          console.log('HUB', 'user signed out', data);
          setUser(null);
          break;
        case 'configured':
          setAuth(Auth); // set auth state from the configured auth.

          if (process.env.NODE_ENV === 'development') {
            window.Auth = Auth;
          }
          // check for current user
          Auth.currentAuthenticatedUser().then(user => {
            setUser(user);
          }).catch(err => {
            setUser(null);
          }).finally(() => setInitializing(false))
          break;

        default:
          break;
      }
    })

    Auth.configure({
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      oauth: {
        domain: process.env.REACT_APP_COGNITO_MAIN_OAUTH_DOMAIN,
        scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
      secure: process.env.REACT_APP_COOKIE_STORAGE_SECURE === "true"
    })

    return () => unsubscribe()
  }, [redirect])

  return {
    initializing,
    user,
    signOut,
    updateUserAttributes,
    verifyEmailValidationCode,
    changePassword
  };
}

export const authContext = createContext();

export const AuthProvider = ({ children }) => {
  const value = useAuthContextValue();
  return <authContext.Provider value={value}>{children}</authContext.Provider>;
}
